import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './Figure.scss';

const Figure = forwardRef(({ children, className, height, width }, ref) => {
    const classNames = cx(styles.root, className, {
        [styles['maintain-ratio']]: width && height,
    });
    const aspectRatio =
        width && height
            ? {
                  paddingBottom: `${(height / width) * 100}%`,
              }
            : null;
    return (
        <figure className={classNames} style={aspectRatio} ref={ref}>
            {children}
        </figure>
    );
});

Figure.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
};

Figure.defaultProps = {
    className: null,
    height: null,
    width: null,
};

export default Figure;
