import PropTypes from 'prop-types';
import React from 'react';

import Review from '../shared/Review';
import FrameSelection from '../shared/FrameSelection';

import Design from './Design';
import Couple from './Couple';
import Details from './Details';
import Stats from './Stats';
import validate from './validate';

const Engagement = (props, formProps) => (
    <>
        {/* TODO: Only pass in the formProps and props required */}
        {props.step === 'frame' && (
            <FrameSelection
                {...formProps}
                {...props}
                project={props.project?.project}
                validate={validate}
                fields={{
                    default: ['baseColor', 'imageMeta'],
                    couple: [
                        'party1Gender',
                        'party2Gender',
                        'party1FirstName',
                        'party2FirstName',
                        'party1Word',
                        'party2Word',
                        'heading',
                    ],
                    details: [
                        'ceremonyLocationData',
                        'theProposal',
                        'experienceWord',
                        'weCelebratedPreposition',
                        'weCelebratedWord',
                        'engagementDate',
                        'engagementTimeOfDay',
                        'wellNeverForget1',
                        'wellNeverForget2',
                    ],
                    stats: [
                        'metLocationPreposition',
                        'metLocationName',
                        'metYear',
                        'thought',
                        'songTitle',
                        'songArtist',
                        'songLyric',
                    ],
                    review: ['accept'],
                }}
            />
        )}
        {props.step === 'stats' && (
            <Stats {...formProps} {...props} project={props.project?.project} />
        )}
        {props.step === 'review' && (
            <Review
                {...formProps}
                {...props}
                project={props.project?.project}
                checkpoints={[
                    'Spelling mistakes',
                    'Grammar issues',
                    'Accuracy of data (i.e. incorrect dates, times and locations)',
                ]}
            />
        )}
        {props.step === 'details' && (
            <Details
                {...formProps}
                {...props}
                project={props.project?.project}
            />
        )}
        {props.step === 'couple' && (
            <Couple
                {...formProps}
                {...props}
                project={props.project?.project}
            />
        )}
        {(!props.step || props.step === 'default') && (
            <Design
                {...formProps}
                {...props}
                project={props.project?.project}
            />
        )}
    </>
);

Engagement.propTypes = {
    project: PropTypes.shape({
        project: PropTypes.object,
    }),
    step: PropTypes.string,
};

export default Engagement;
