import React, { useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useUpload } from 'stillnovel/hooks/useAWS';
import { useDropzone } from 'react-dropzone';
import {
    getFileExtensionFromPath,
    getNaturalImageDimensions,
} from 'stillnovel/utils';
import Button from 'stillnovel/components/UI/Button';
import Figure from 'stillnovel/components/UI/Figure';
import Img from 'stillnovel/components/UI/Img';
import Text from 'stillnovel/components/UI/Text';
import SvgIcon from 'stillnovel/components/UI/SvgIcon';
import CropImage from 'stillnovel/components/UI/CropImage';
import { cover } from 'intrinsic-scale';
import cx from 'classnames';
import d from 'debug';
import Portal from 'stillnovel/components/UI/Portal';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';

import Toolbar from './Toolbar';
import styles from './ImageInput.scss';

const BASE_URL = 'https://cdn.stillnovel.com/users/uploads';
const DEFAULT_PREVIEW_SIZE = 800;

const debug = d('App:UI:ImageInput');

const ConfirmDelete = ({ active, onConfirm }) => {
    return (
        <div
            className={cx(
                styles['delete-confirmation'],
                active && styles['delete-confirmation--active']
            )}
        >
            <div className={styles['delete-confirmation-inner']}>
                <Text theme="body1-alt" tag="p">
                    Are you sure you want to replace this photo?
                </Text>
                <span className={styles['delete-confirmation-buttons']}>
                    <Button block small onClick={onConfirm(true)}>
                        Delete
                    </Button>
                    <Button block outline small onClick={onConfirm(false)}>
                        Cancel
                    </Button>
                </span>
            </div>
        </div>
    );
};

ConfirmDelete.propTypes = {
    active: PropTypes.any,
    onConfirm: PropTypes.func,
};

const Progress = ({ processing, uploadProgress, onTransitionEnd }) => {
    const transitionTime = uploadProgress > 0 ? `0.5s` : `0s`;
    return (
        <div
            className={cx(styles.progress, {
                [styles['progress-processing']]: processing,
            })}
        >
            <div
                className={styles['progress-bar']}
                style={{
                    transform: `translateX(${-100 + uploadProgress}%)`,
                    transition: `opacity ${transitionTime}, transform ${transitionTime}`,
                }}
                onTransitionEnd={onTransitionEnd}
            />
        </div>
    );
};

Progress.propTypes = {
    onTransitionEnd: PropTypes.func,
    processing: PropTypes.bool,
    uploadProgress: PropTypes.number,
};

const states = {
    DEFAULT: 'DEFAULT',
    CROPPING: 'CROPPING',
    DELETE: 'DELETE',
    ERROR: 'ERROR',
};

const ImageInput = ({
    input,
    meta: { touched, error },
    targetHeight,
    targetWidth,
}) => {
    const cropImageRef = useRef(null);

    const { value, onChange } = input;

    const [state, setState] = useState(states.DEFAULT);
    const [loadingImage, setLoadingImage] = useState(false);

    const [lowResolution, setLowResolution] = useState(false);
    const [warningMessage, setWarningMessage] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);

    const [upload, _uploadProgress] = useUpload();

    useEffect(() => {
        setUploadProgress(_uploadProgress);
    }, [_uploadProgress]);

    const handleProgressTransitionEnd = () => {
        uploadProgress === 100 && setLoadingImage(true);
    };

    const handleImageOnLoad = () => {
        setUploadProgress(0);
        setLoadingImage(false);
    };

    const getCrop = useCallback(
        ({ width: imgWidth, height: imgHeight }) => {
            const { width, height, x, y } = cover(
                targetWidth,
                targetHeight,
                imgWidth,
                imgHeight
            );

            const percentCrop = {
                x: -100 * (x / width),
                y: -100 * (y / height),
                width: 100 * (targetWidth / width),
                height: 100 * (targetHeight / height),
                aspect: targetWidth / targetHeight,
                unit: '%',
            };

            return percentCrop;
        },
        [targetHeight, targetWidth]
    );

    useEffect(() => {
        if (
            input.value &&
            (targetWidth !== input.value?.targetWidth ||
                targetHeight !== input.value?.targetHeight)
        ) {
            debug(
                'targetWidth or targetHeight changed, recalculate crop & save'
            );

            const crop = getCrop({
                width: input.value.width,
                height: input.value.height,
            });

            onChange({
                ...input.value,
                targetWidth,
                targetHeight,
                crop,
            });
        }
    }, [input, targetHeight, targetWidth, getCrop, onChange]);

    const classNames = cx(styles['input-group'], {
        [styles['input-group-error']]: touched && error,
    });

    const checkResolution = useCallback(
        async path => {
            if (value.src) {
                const { width, height } = await getNaturalImageDimensions(
                    path
                ).catch(() => {
                    return { width: 0, height: 0 };
                });
                const isLowResolution =
                    width < targetWidth || height < targetHeight;
                setWarningMessage(
                    isLowResolution
                        ? 'Warning: Low resolution photo, cropping disabled'
                        : ''
                );
                setLowResolution(isLowResolution);
            } else {
                setWarningMessage('');
                setLowResolution(false);
            }
        },
        [targetHeight, targetWidth, value.src]
    );

    const onDrop = useCallback(
        async acceptedFiles => {
            // Do something with the files
            if (acceptedFiles[0]) {
                try {
                    const file = await upload(acceptedFiles[0]);

                    const [thumbImageDims, ogImageDims] = await Promise.all([
                        getNaturalImageDimensions(
                            `${BASE_URL}/${file.cognitoIdentityId}/${DEFAULT_PREVIEW_SIZE}/${file.filename}`
                        ),
                        getNaturalImageDimensions(
                            `${BASE_URL}/${file.cognitoIdentityId}/${file.filename}`
                        ),
                    ]);

                    const ogImageRatio = ogImageDims.width / ogImageDims.height;

                    // orientation normalized thumb
                    const thumbImageRatio =
                        thumbImageDims.width / thumbImageDims.height;

                    // check corrected against og and see if we flipped it
                    const flipped =
                        Number(ogImageRatio - thumbImageRatio).toFixed(2) >
                        0.01;

                    const imageDims = {
                        width: flipped ? ogImageDims.height : ogImageDims.width,
                        height: flipped
                            ? ogImageDims.width
                            : ogImageDims.height,
                    };
                    const crop = getCrop({
                        width: imageDims.width,
                        height: imageDims.height,
                    });

                    onChange({
                        src: `${file.filename}`,
                        width: imageDims.width,
                        height: imageDims.height,
                        ...file,
                        crop,
                    });
                } catch (e) {
                    console.warn('Upload issue', e);
                    setState(states.ERROR);
                }
            }
        },
        [upload, getCrop, onChange]
    );

    const handleOpenCrop = useCallback(e => {
        e.preventDefault();
        e.stopPropagation();
        setState(states.CROPPING);
    }, []);

    const handleCloseCrop = useCallback(e => {
        e.preventDefault();
        setState(states.DEFAULT);
    }, []);

    const renderDeleteConfirmation = useCallback(e => {
        e.preventDefault();
        setState(states.DELETE);
    }, []);

    const handleClick = useCallback(
        e => {
            e.preventDefault();
            e.stopPropagation();
            if (e.target.dataset.id !== 'crop') {
                renderDeleteConfirmation(e);
            }
            return false;
        },
        [renderDeleteConfirmation]
    );

    const { fileRejections, getRootProps, getInputProps, isDragActive } =
        useDropzone({
            onDrop,
            multiple: false,
            accept: {
                'image/*': ['.png', '.jpg', '.jpeg', '.heic', '.heif', '.webp'],
            },
        });

    useEffect(() => {
        if (fileRejections.length > 0) {
            const warningMessage = `${getFileExtensionFromPath(
                fileRejections[0].file.path
            ).toUpperCase()} is not supported. Acceptable file types: JPG, PNG.`;
            setWarningMessage(warningMessage);
        }
    }, [fileRejections]);

    const handleConfirmDelete = useCallback(
        shouldDelete => e => {
            e.preventDefault();
            if (shouldDelete) {
                onChange({});
            }
            setState(states.DEFAULT);
        },
        [onChange]
    );

    useEffect(() => {
        checkResolution(
            `${BASE_URL}/${value.cognitoIdentityId}/${value.filename}`
        );
    }, [checkResolution, onChange, value.cognitoIdentityId, value.filename]);

    useEffect(() => {
        if (state === states.CROPPING) {
            disableBodyScroll(cropImageRef.current);
        }
        return () => {
            clearAllBodyScrollLocks();
        };
    }, [state]);

    // Instantly show crop UI when upload is finished
    useEffect(() => {
        value.src && uploadProgress === 100 && setState(states.CROPPING);
    }, [uploadProgress, value.src]);

    return (
        <div className={classNames}>
            <Text
                tag="h3"
                theme="form-title"
                className={styles['input-group-header']}
            >
                <span>
                    {value?.src ? 'Edit your Photo' : 'Choose your Photo'}
                </span>
                {warningMessage && <Text isWarning>{warningMessage}</Text>}
            </Text>
            <div className={styles['drop-zone-outer']}>
                <Progress
                    processing={loadingImage}
                    uploadProgress={uploadProgress}
                    onTransitionEnd={handleProgressTransitionEnd}
                />
                <ConfirmDelete
                    active={state == states.DELETE}
                    onConfirm={handleConfirmDelete}
                />

                <Portal>
                    {state === states.CROPPING && (
                        <CropImage
                            ref={cropImageRef}
                            key={`${targetWidth}${targetHeight}`}
                            value={input.value}
                            src={`${BASE_URL}/${value.cognitoIdentityId}/${DEFAULT_PREVIEW_SIZE}/${value.filename}`}
                            targetWidth={targetWidth}
                            targetHeight={targetHeight}
                            onClose={handleCloseCrop}
                            onSave={crop => {
                                onChange({ ...value, crop });
                            }}
                        />
                    )}
                </Portal>

                <div className={styles['drop-zone']}>
                    <div
                        {...getRootProps({
                            onClick: value.filename ? handleClick : null,
                        })}
                        className={cx(styles['drop-zone-inner'], {
                            [styles['drop-zone--active']]: isDragActive,
                        })}
                    >
                        {value?.src ? (
                            <>
                                <div className={styles['image-thumb-outer']}>
                                    <Figure className={styles['image-thumb']}>
                                        <Img
                                            crossOrigin="anonymous"
                                            src={`${BASE_URL}/${value.cognitoIdentityId}/${DEFAULT_PREVIEW_SIZE}/${value.filename}`}
                                            className={styles.img}
                                            onLoad={handleImageOnLoad}
                                        />
                                    </Figure>
                                </div>
                                <Toolbar
                                    hasLowResPhoto={lowResolution}
                                    onClickCrop={handleOpenCrop}
                                    onClickDelete={renderDeleteConfirmation}
                                />
                            </>
                        ) : (
                            <Text
                                theme="body1-alt"
                                className={styles['upload-lockup']}
                            >
                                <span className={styles['upload-lockup-icon']}>
                                    <SvgIcon iconType="upload" />
                                </span>
                                {uploadProgress === 0 &&
                                    (isDragActive
                                        ? 'Drop File'
                                        : 'Select File')}
                                {uploadProgress > 0 && (
                                    <>
                                        Uploading file...{' '}
                                        <span
                                            className={styles.percentage}
                                        >{`${uploadProgress}%`}</span>
                                    </>
                                )}
                                <br />
                                (jpg, png)
                            </Text>
                        )}
                        <input
                            {...getInputProps()}
                            className={styles['file-input']}
                        />
                        {state === states.ERROR && (
                            <p className={styles.error}>
                                There was an issue saving your image. Please try
                                again.
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

ImageInput.propTypes = {
    input: PropTypes.shape({
        onChange: PropTypes.func,
        value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    }).isRequired,
    meta: PropTypes.any.isRequired,
    targetHeight: PropTypes.number,
    targetWidth: PropTypes.number,
};

export default ImageInput;
