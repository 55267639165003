import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import StepNav from 'stillnovel/components/App/Project/Builders/ArtPrint/StepNav';
import { Container, Col, Row } from 'stillnovel/components/UI/Grid';
import DateInput from 'stillnovel/components/UI/Input/DateInput';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import Form from 'stillnovel/components/UI/Form';
import FormHeader from 'stillnovel/components/App/Project/Builders/ArtPrint/FormHeader';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import PlacesAutocomplete from 'stillnovel/components/UI/PlacesAutocomplete';
import Text from 'stillnovel/components/UI/Text';
import { FieldSelect } from 'stillnovel/components/UI/Input';

import validate from '../validate';

let Details = ({
    handleSubmit,
    invalid,
    previousPage,
    project,
    submitting,
}) => {
    return (
        <Fragment>
            <Form>
                <FormHeader heading="Engagement Details" />
                <Container>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="ceremonyLocationData"
                            >
                                Proposal City and State
                            </Text>
                            <Field
                                id="ceremonyLocationData"
                                name="ceremonyLocationData"
                                component={PlacesAutocomplete}
                                placeholder="Ex: “Los Angeles, CA”"
                                autoComplete={`${project.id}-ceremonyLocation`}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="theProposal"
                            >
                                Proposal Location (The place)
                            </Text>
                            <Field
                                id="theProposal"
                                name="theProposal"
                                component={FieldInput}
                                placeholder="Ex: “St”. Andrews Church"
                                maxLength="23"
                                autoComplete={`${project.id}-theProposal`}
                                autoCorrect="off"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text tag="span" theme="form-title">
                                <Text
                                    tag="label"
                                    theme="form-title"
                                    htmlFor="experienceWord"
                                    autoCorrect="off"
                                >
                                    The day was
                                </Text>
                                <Field
                                    id="experienceWord"
                                    name="experienceWord"
                                    component={FieldInput}
                                    placeholder="Ex: perfect"
                                    maxLength="23"
                                    autoComplete={`${project.id}-experienceWord`}
                                />
                            </Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text tag="span" theme="form-title">
                                <Text
                                    tag="label"
                                    theme="form-title"
                                    htmlFor="weCelebratedWord"
                                    autoCorrect="off"
                                >
                                    We celebrated
                                </Text>
                                <br />
                                <Field
                                    inline
                                    id="weCelebratedPreposition"
                                    name="weCelebratedPreposition"
                                    component={FieldSelect}
                                >
                                    <option value="">--</option>
                                    <option value="with">with</option>
                                    <option value="at">at</option>
                                    <option value="in">in</option>
                                </Field>
                                <Field
                                    inline
                                    id="weCelebratedWord"
                                    name="weCelebratedWord"
                                    component={FieldInput}
                                    placeholder="Ex: champagne"
                                    maxLength="23"
                                    autoComplete={`${project.id}-weCelebratedWord`}
                                />
                            </Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text tag="label" theme="form-title">
                                Engagement Date
                            </Text>
                            <Field
                                name="engagementDate"
                                type="select"
                                component={DateInput}
                                showTime={false}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="engagementTimeOfDay"
                            >
                                The time of day
                            </Text>
                            <Field
                                id="engagementTimeOfDay"
                                name="engagementTimeOfDay"
                                component={FieldSelect}
                                stretch
                            >
                                <option value="">--</option>
                                <option value="morning">Morning</option>
                                <option value="afternoon">Afternoon</option>
                                <option value="evening">Evening</option>
                                <option value="night">Night</option>
                            </Field>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text tag="span" theme="form-title">
                                We will never forget{' '}
                                <Field
                                    inline
                                    stretch
                                    name="wellNeverForget1"
                                    component={FieldInput}
                                    placeholder="Ex: “how nervous he looked”"
                                    autoComplete={`${project.id}-wellNeverForget1`}
                                    autoCapitalize="off"
                                    maxLength={45}
                                    style={{ minWidth: '230px' }}
                                />{' '}
                                and{' '}
                                <Field
                                    inline
                                    stretch
                                    name="wellNeverForget2"
                                    component={FieldInput}
                                    placeholder="Ex: “call to the family”"
                                    autoComplete={`${project.id}-wellNeverForget2`}
                                    autoCapitalize="off"
                                    maxLength={45}
                                    style={{ minWidth: '190px' }}
                                />
                            </Text>
                        </Col>
                    </Row>
                </Container>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

Details.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    previousPage: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    submitting: PropTypes.bool,
};

Details.defaultProps = {
    invalid: false,
    submitting: false,
};

Details = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(Details);

export default Details;
