import {
    trimWhitespaceFromValues,
    validateThought,
} from 'stillnovel/utils/validation';

export default values => {
    // Trim leading and trailing whitespace from values object
    values = trimWhitespaceFromValues(values);

    const withoutAstrologyFields =
        values.withoutAstrology === 'true'
            ? {
                  nickname: !values.nickname && 'Required',
                  gender: !values.gender && 'Required',
                  childWord: !values.childWord && 'Required',
              }
            : {};

    const errors = {
        accept:
            !values.accept &&
            values.accept !== true &&
            'Please confirm your artwork is error-free & ready for print.',
        baseColor: !values.baseColor && 'Required',
        birthDate: !values.birthDate && 'Required',
        birthLocationData: !values.birthLocationData && 'Required',
        dueDate: !values.dueDate && 'Required',
        firstName: !values.firstName && 'Required',
        image: !values.image && 'Required',
        imageMeta: !values.imageMeta && 'Required',
        lastName: !values.lastName && 'Required',
        len: !values.len && 'Required',
        thought: validateThought(values.thought),
        weightLbs: !values.weightLbs && 'Required',
        weightOz: !values.weightOz && 'Required',
        shopifyProductId: !values.shopifyProductId && 'Required',
        ...withoutAstrologyFields,
    };

    return errors;
};
