import PropTypes from 'prop-types';
import React from 'react';

import FrameSelection from '../shared/FrameSelection';
import Review from '../shared/Review';

import Background from './Background';
import Details from './Details';
import Details2 from './Details2';
import validate from './validate';

const Pet = (props, formProps) => (
    <>
        {/* TODO: Only pass in the formProps and props required */}
        {props.step === 'frame' && (
            <FrameSelection
                {...formProps}
                {...props}
                project={props.project?.project}
                validate={validate}
                fields={{
                    default: [
                        'baseColor',
                        'pet',
                        'firstName',
                        'middleName',
                        'lastName',
                        'nickname',
                        'gender',
                        'dateType',
                        'date',
                        'imageMeta',
                    ],
                    details: [
                        'thought',
                        'temperament1',
                        'temperament2',
                        'favoriteToy',
                        'favoriteTreat',
                        'breed',
                        'dogBreedGroup',
                        'catBreedGroup',
                        'otherBreedGroup',
                    ],
                    details2: [
                        'metLocationData',
                        'arrivedHomeNumber',
                        'arrivedHomePeriod',
                        'metPronoun',
                        'metPlacePreposition',
                        'metPlaceName',
                        'choseReason',
                        'description',
                        'aboutSleep',
                        'aboutAlways',
                    ],
                    review: ['accept'],
                }}
            />
        )}
        {props.step === 'review' && (
            <Review
                {...formProps}
                {...props}
                project={props.project?.project}
                checkpoints={[
                    'Spelling mistakes',
                    'Grammar issues',
                    'Accuracy of data (i.e. incorrect dates, times and locations)',
                ]}
            />
        )}
        {props.step === 'details2' && (
            <Details2
                {...formProps}
                {...props}
                project={props.project?.project}
            />
        )}
        {props.step === 'details' && (
            <Details
                {...formProps}
                {...props}
                project={props.project?.project}
            />
        )}
        {!props.step && (
            <Background
                {...formProps}
                {...props}
                project={props.project?.project}
            />
        )}
    </>
);

Pet.propTypes = {
    project: PropTypes.shape({
        project: PropTypes.object,
    }),
    step: PropTypes.string,
};

export default Pet;
