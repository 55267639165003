import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Field from 'redux-form/es/Field';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import reduxForm from 'redux-form/es/reduxForm';
import { Col, Row } from 'stillnovel/components/UI/Grid';
import Form from 'stillnovel/components/UI/Form';
import FormHeader from 'stillnovel/components/App/Project/Builders/ArtPrint/FormHeader';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import Text from 'stillnovel/components/UI/Text';

import StepNav from '../../../StepNav';

import styles from './Review.scss';

let Review = ({
    handleSubmit,
    submitting,
    checkpoints,
    previousPage,
    metadata,
}) => {
    return (
        <Fragment>
            <Form>
                <FormHeader heading="Artwork Review" />
                <div className={styles.root}>
                    <Text tag="h2" theme="form-title">
                        Please check your print for...
                    </Text>
                    <ul className={styles['unordered-list']}>
                        {checkpoints.map((point, i) => (
                            <Text key={i} tag="li" theme="body1-alt">
                                {point}
                            </Text>
                        ))}
                    </ul>
                    <Field
                        name="accept"
                        id="accept"
                        component={({ id, input }) => {
                            return (
                                <Text
                                    tag="label"
                                    theme="body1-alt"
                                    className={cx(styles['accept-checkbox'], {
                                        [styles.approved]: input.value === true,
                                    })}
                                >
                                    <input
                                        {...input}
                                        checked={!!input.value}
                                        id={id}
                                        name={input.name}
                                        type="checkbox"
                                    />{' '}
                                    <span>
                                        I confirm my artwork is error-free &amp;
                                        ready for print
                                    </span>
                                </Text>
                            );
                        }}
                    />
                    <FormHeader heading="Order Note" />
                    <Row>
                        <Col colSpan={2}>
                            <Text tag="p" theme="body1">
                                We want you to be completely satisfied with your
                                order. Our design team reviews every print for
                                image quality and text legibility to ensure that
                                your print looks amazing. Please note that while
                                we do our best to make sure everything is
                                perfect, we do not check for spelling or grammar
                                errors. If you have any special requests or
                                concerns, please let us know and we&#39;ll do
                                our best to accommodate them. <br />
                            </Text>
                        </Col>
                        <Col colSpan={2}>
                            <Field
                                stretch
                                id="designNote"
                                name="designNote"
                                type="textarea"
                                component={FieldInput}
                                maxLength="255"
                                showErrorText
                                placeholder="255 character max"
                            />
                        </Col>
                    </Row>
                </div>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage },
                        {
                            onClick: handleSubmit,
                            disabled: metadata.accept !== true || submitting,
                            label: 'Finishing Options',
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

Review.propTypes = {
    checkpoints: PropTypes.array,
    handleSubmit: PropTypes.func.isRequired,
    metadata: PropTypes.shape({
        accept: PropTypes.bool,
    }),
    previousPage: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
};

Review.defaultProps = {
    submitting: false,
    checkpoints: [],
};

Review = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: true,
})(Review);

export default Review;
