import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSWR from 'swr';
import { useMount } from 'react-use';
import { Helmet } from 'react-helmet';
import Button from 'stillnovel/components/UI/Button';
import Loader from 'stillnovel/components/UI/Loader';
import AuthTrigger from 'stillnovel/components/App/Auth/Trigger';
import { states } from 'stillnovel/store/auth/actions';

import Text from 'stillnovel/components/UI/Text';
import SN from 'stillnovel/services';

import { deleteProject, getProjects } from 'stillnovel/store/projects/actions';

import ProjectTile from 'stillnovel/components/App/Account/Dashboard/ProjectTile';

import Header from 'stillnovel/components/App/Header';

import getConfig from 'core/config';

import styles from './Dashboard.scss';

const Dashboard = () => {
    const projects = useSelector(state => state.projects.items);
    const isLoading = useSelector(state => state.projects.isLoading);
    const user = useSelector(state => state.auth.user);
    const authState = useSelector(state => state.auth.state);

    const guestUser = user && user.role === 'guest';

    const dispatch = useDispatch();

    // Fetch all products in your shop
    const { data: shopifyProducts } = useSWR('products', () =>
        SN.shopifyClient.product.fetchAll()
    );

    const productsKeyed =
        shopifyProducts?.reduce((a, o) => {
            a[o.handle] = o;
            const keyed = o.variants.reduce((a, o) => {
                a[o.id] = o;
                if (o.handle) {
                    a[o.handle] = o;
                }
                return a;
            }, a);
            return { ...a, ...keyed };
        }, {}) || {};

    useMount(() => {
        dispatch(getProjects());
    });

    function renderProjects() {
        const sortedProjects = [...projects].sort((a, b) => {
            return new Date(b.updatedAt) - new Date(a.updatedAt);
        });

        return (
            <div className={styles.content}>
                <Header headline="My Projects" />
                {guestUser && (
                    <section className={styles.guestUserContent}>
                        <>
                            <Text tag="h3" theme="title">
                                Log in to view your projects
                            </Text>
                            <Text tag="p" theme="body1">
                                You can create, view, or edit projects across
                                all devices once you’ve logged in.
                            </Text>
                            <AuthTrigger>
                                <Button block>Log in</Button>
                            </AuthTrigger>
                        </>
                    </section>
                )}

                <div className={styles.list}>
                    {guestUser && projects.length > 0 && (
                        <Text tag="h4" theme="title">
                            Recent Drafts
                        </Text>
                    )}

                    {!guestUser && projects.length > 0 && (
                        <Text tag="h4" theme="title">
                            Recently Saved Projects
                        </Text>
                    )}

                    {sortedProjects.length === 0 ? (
                        <>
                            <Button
                                theme="title"
                                to={`${getConfig('marcomBaseUrl')}/products`}
                            >
                                Create your first project
                            </Button>
                            <ul style={{ opacity: 0.4 }}>
                                {Array.from({ length: 1 }).map((_, i) => (
                                    <ProjectTile key={i} project={{}} />
                                ))}
                            </ul>
                        </>
                    ) : (
                        <ul>
                            {sortedProjects.map(project => (
                                <ProjectTile
                                    key={project.id}
                                    project={project}
                                    productsKeyed={productsKeyed}
                                    deleteProject={deleteProject}
                                />
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        );
    }

    function renderLoading() {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <Loader
                    align="left"
                    theme="small"
                    text="Fetching projects..."
                />
            </div>
        );
    }

    function renderContent() {
        if (isLoading || authState !== states.LOGGED_IN) {
            return renderLoading();
        }
        return renderProjects();
    }

    return (
        <Fragment>
            <Helmet>
                <title>Projects</title>
            </Helmet>
            {renderContent()}
        </Fragment>
    );
};

export default Dashboard;
