import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './Img.scss';

function Img({ className, loading, onLoad, decoding, priority, ...props }) {
    const imgRef = useRef(null);
    const [isLoaded, setLoaded] = useState(false);

    const onLoaded = useCallback(() => {
        requestAnimationFrame(() => setLoaded(true));
    }, []);

    useEffect(() => {
        let img = new Image();
        img.onload = () => onLoaded;
        img.onerror = () => onLoaded;
        imgRef.current.complete && onLoaded();
        return function cleanup() {
            img = null;
        };
    }, [imgRef, onLoaded]);

    useEffect(() => {
        if (isLoaded) {
            onLoad();
        }
    }, [isLoaded, onLoad]);

    const classNames = cx(styles.img, className, {
        [styles['is-loaded']]: isLoaded,
    });

    return (
        <>
            <img
                {...props}
                ref={imgRef}
                className={classNames}
                draggable={false}
                loading={priority ? 'eager' : loading}
                decoding={priority ? 'sync' : decoding}
                // eslint-disable-next-line react/no-unknown-property
                fetchPriority={priority ? 'high' : 'auto'}
                onLoad={onLoaded}
            />
        </>
    );
}

Img.propTypes = {
    className: PropTypes.string,
    decoding: PropTypes.any,
    loading: PropTypes.string,
    onLoad: PropTypes.func,
    priority: PropTypes.bool,
    src: PropTypes.string.isRequired,
};

Img.defaultProps = {
    className: null,
    decoding: 'async',
    loading: 'lazy',
    onLoad: () => {},
    priority: false,
};

export default Img;
