import PropTypes from 'prop-types';
import React from 'react';

import FrameSelection from '../shared/FrameSelection';
import Review from '../shared/Review';

import Background from './Background';
import Details from './Details';
import Stats from './Stats';
import validate from './validate';

const Travel = (props, formProps) => (
    <>
        {/* TODO: Only pass in the formProps and props required */}
        {props.step === 'frame' && (
            <FrameSelection
                {...formProps}
                {...props}
                project={props.project?.project}
                validate={validate}
                fields={{
                    default: [
                        'baseColor',
                        'heading',
                        'imageMeta',
                        'image',
                        'region',
                    ],
                    details: [
                        'ateStatement',
                        'drankStatement',
                        'endDate',
                        'numberOfTravelers',
                        'startDate',
                        'stayedStatement',
                        'thought',
                        'tripLocationData',
                        'weatherStatement',
                        'woreStatement',
                    ],
                    stats: [
                        'lovedStatement',
                        'neverForget1',
                        'neverForget2',
                        'rating',
                        'traveledStatement',
                        'tripAdjective',
                    ],
                    review: ['accept'],
                }}
            />
        )}
        {props.step === 'stats' && (
            <Stats {...formProps} {...props} project={props.project?.project} />
        )}
        {props.step === 'review' && (
            <Review
                {...formProps}
                {...props}
                project={props.project?.project}
                checkpoints={[
                    'Spelling mistakes',
                    'Grammar issues',
                    'Accuracy of data (i.e. incorrect dates, times and locations)',
                ]}
            />
        )}
        {props.step === 'details' && (
            <Details
                {...formProps}
                {...props}
                project={props.project?.project}
            />
        )}
        {!props.step && (
            <Background
                {...formProps}
                {...props}
                project={props.project?.project}
            />
        )}
    </>
);

Travel.propTypes = {
    project: PropTypes.shape({
        project: PropTypes.object,
    }),
    step: PropTypes.string,
};
export default Travel;
