import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import StepNav from 'stillnovel/components/App/Project/Builders/ArtPrint/StepNav';
import { Container, Col, Row } from 'stillnovel/components/UI/Grid';
import DateInput from 'stillnovel/components/UI/Input/DateInput';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import Form from 'stillnovel/components/UI/Form';
import FieldSelect from 'stillnovel/components/UI/Input/FieldSelect';
import FormHeader from 'stillnovel/components/App/Project/Builders/ArtPrint/FormHeader';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import PlacesAutocomplete from 'stillnovel/components/UI/PlacesAutocomplete';
import Text from 'stillnovel/components/UI/Text';

import validate from '../validate';

let Details = ({
    handleSubmit,
    invalid,
    previousPage,
    project,
    submitting,
}) => {
    return (
        <Fragment>
            <Form>
                <FormHeader heading="Details" />
                <Container>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="birthLocationData"
                            >
                                Birth City, State
                            </Text>
                            <Field
                                name="birthLocationData"
                                component={PlacesAutocomplete}
                                placeholder="Ex: “Los Angeles, CA”"
                                autoComplete={`${project.id}-birthPlace`}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="thought"
                            >
                                A Thought (optional)
                            </Text>
                            <Field
                                stretch
                                id="thought"
                                name="thought"
                                type="textarea"
                                component={FieldInput}
                                maxLength="124"
                                showErrorText
                                placeholder="A sentence or two about what you think makes this person special."
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text tag="label" theme="form-title">
                                Birth Date &amp; Time
                            </Text>
                            <Field
                                name="birthDate"
                                type="select"
                                showErrorText
                                component={DateInput}
                                showTime
                            />
                        </Col>
                        <Col>
                            <Text tag="label" theme="form-title">
                                Due Date
                            </Text>
                            <Field
                                name="dueDate"
                                type="select"
                                showErrorText
                                component={DateInput}
                            />
                        </Col>
                    </Row>
                    <Row alignment="bottom">
                        <Col>
                            <Text
                                tag="label"
                                theme="form-title"
                                htmlFor="weightLbs"
                            >
                                Weight
                            </Text>
                            <Field
                                id="weightLbs"
                                name="weightLbs"
                                component={FieldSelect}
                                stretch
                            >
                                <option value="" disabled>
                                    --
                                </option>
                                <option value="1">1 lbs</option>
                                <option value="2">2 lbs</option>
                                <option value="3">3 lbs</option>
                                <option value="4">4 lbs</option>
                                <option value="5">5 lbs</option>
                                <option value="6">6 lbs</option>
                                <option value="7">7 lbs</option>
                                <option value="8">8 lbs</option>
                                <option value="9">9 lbs</option>
                                <option value="10">10 lbs</option>
                                <option value="11">11 lbs</option>
                                <option value="12">12 lbs</option>
                                <option value="13">13 lbs</option>
                                <option value="14">14 lbs</option>
                                <option value="15">15 lbs</option>
                                <option value="16">16 lbs</option>
                            </Field>
                        </Col>
                        <Col>
                            <Field
                                id="weightOz"
                                name="weightOz"
                                component={FieldSelect}
                                stretch
                            >
                                <option value="" disabled>
                                    --
                                </option>
                                <option value="0">0 oz</option>
                                <option value="1">1 oz</option>
                                <option value="2">2 oz</option>
                                <option value="3">3 oz</option>
                                <option value="4">4 oz</option>
                                <option value="5">5 oz</option>
                                <option value="6">6 oz</option>
                                <option value="7">7 oz</option>
                                <option value="8">8 oz</option>
                                <option value="9">9 oz</option>
                                <option value="10">10 oz</option>
                                <option value="11">11 oz</option>
                                <option value="12">12 oz</option>
                                <option value="13">13 oz</option>
                                <option value="14">14 oz</option>
                                <option value="15">15 oz</option>
                            </Field>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text tag="label" theme="form-title" htmlFor="len">
                                Length
                            </Text>
                            <Field
                                id="len"
                                name="len"
                                component={FieldSelect}
                                stretch
                            >
                                <option value="" disabled>
                                    --
                                </option>
                                {Array(81)
                                    .fill()
                                    .map((_, i) => {
                                        const step = (i * 2.5) / 10;
                                        const adjustedStep = step + 10;
                                        return (
                                            <option
                                                key={i}
                                                value={`${adjustedStep}`}
                                            >
                                                {adjustedStep}
                                                &#8221;
                                            </option>
                                        );
                                    })}
                            </Field>
                        </Col>
                    </Row>
                </Container>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

Details.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    previousPage: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    submitting: PropTypes.bool,
};

Details.defaultProps = {
    invalid: false,
    submitting: false,
};

Details = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(Details);

export default Details;
