import PropTypes from 'prop-types';
import React from 'react';

import FrameSelection from '../shared/FrameSelection';
import Review from '../shared/Review';

import Background from './Background';
import Details from './Details';
import Stats from './Stats';
import validate from './validate';

const BirthStat = (props, formProps) => (
    <>
        {/* TODO: Only pass in the formProps and props required */}
        {props.step === 'frame' && (
            <FrameSelection
                {...formProps}
                {...props}
                project={props.project?.project}
                validate={validate}
                fields={{
                    default: [
                        'baseColor',
                        'imageMeta',
                        'firstName',
                        'lastName',
                    ],
                    details: [
                        'birthDate',
                        'birthLocationData',
                        'dueDate',
                        'thought',
                        'len',
                        'weightLbs',
                        'weightOz',
                    ],
                    stats: ['nickname', 'gender', 'childWord'],
                    review: ['accept'],
                }}
            />
        )}
        {props.step === 'stats' && (
            <Stats {...formProps} {...props} project={props.project?.project} />
        )}
        {props.step === 'review' && (
            <Review
                {...formProps}
                {...props}
                project={props.project?.project}
                checkpoints={[
                    'Spelling mistakes',
                    'Grammar issues',
                    'Accuracy of data (i.e. incorrect dates, times and locations)',
                ]}
            />
        )}
        {props.step === 'details' && (
            <Details
                {...formProps}
                {...props}
                project={props.project?.project}
            />
        )}
        {!props.step && (
            <Background
                {...formProps}
                {...props}
                project={props.project?.project}
            />
        )}
    </>
);

BirthStat.propTypes = {
    metadata: PropTypes.object,
    project: PropTypes.shape({
        project: PropTypes.object,
    }),
    step: PropTypes.string,
};
export default BirthStat;
