import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import { Col, Row } from 'stillnovel/components/UI/Grid';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import Form from 'stillnovel/components/UI/Form';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import Text from 'stillnovel/components/UI/Text';
import ImageInput from 'stillnovel/components/UI/Input/ImageInput';
import productsConfig from 'stillnovel/components/App/Project/config';

import FormHeader from '../../../FormHeader';
import ColorSelector from '../../shared/ColorSelector';
import StyleSelector from '../../shared/StyleSelector';
import StepNav from '../../../StepNav';
import validate from '../validate';

let BackgroundForm = ({
    handleSubmit,
    invalid,
    metadata,
    previousPage,
    project,
    submitting,
}) => {
    const config = productsConfig['birth-stat'];
    const style = metadata?.style === 'vintage' ? 'vintage' : 'modern';
    const styleConfig = config.styles.find(
        styleConfig => style == styleConfig.styleHandle
    );

    return (
        <Fragment>
            <Form>
                <FormHeader heading="Background" />
                <StyleSelector />
                <Row alignment="bottom">
                    <Col>
                        <Text
                            tag="label"
                            theme="form-title"
                            htmlFor="firstName"
                        >
                            Name
                        </Text>
                        <Field
                            id="firstName"
                            name="firstName"
                            type="text"
                            component={FieldInput}
                            placeholder="First"
                            autoComplete={`${project.id}-firstName`}
                            autoCorrect="off"
                        />
                    </Col>
                    <Col>
                        <Field
                            name="middleName"
                            type="text"
                            component={FieldInput}
                            placeholder="Middle"
                            autoComplete={`${project.id}-middleName`}
                            autoCorrect="off"
                        />
                    </Col>
                    <Col>
                        <Field
                            name="lastName"
                            type="text"
                            component={FieldInput}
                            placeholder="Last"
                            autoComplete={`${project.id}-lastName`}
                            autoCorrect="off"
                        />
                    </Col>
                </Row>
                <Field
                    name="imageMeta"
                    component={ImageInput}
                    targetWidth={styleConfig.photoTargetWidth}
                    targetHeight={styleConfig.photoTargetHeight}
                />
                <Row>
                    <Col>
                        <Field
                            name="baseColor"
                            component={ColorSelector}
                            style={metadata.style || 'modern'}
                        />
                        <Text tag="p" theme="caption">
                            *We apply a softening layer over all photos to allow
                            for the design and text to subtly pop over your
                            image.
                        </Text>
                    </Col>
                </Row>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage, disabled: true },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

BackgroundForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    metadata: PropTypes.object.isRequired,
    previousPage: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    submitting: PropTypes.bool,
};

BackgroundForm.defaultProps = {
    invalid: false,
    submitting: false,
};

BackgroundForm = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(BackgroundForm);

export default BackgroundForm;
