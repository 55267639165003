import React from 'react';
import PropTypes from 'prop-types';
import Button from 'stillnovel/components/UI/Button';
import SvgIcon from 'stillnovel/components/UI/SvgIcon';
import Text from 'stillnovel/components/UI/Text';

import styles from './Toolbar.scss';

const Toolbar = ({ hasLowResPhoto, onClickCrop, onClickDelete }) => {
    return (
        <nav className={styles.toolbar}>
            <Button
                data-id="crop"
                onClick={onClickCrop}
                className={styles['toolbar-button']}
                disabled={hasLowResPhoto}
            >
                <SvgIcon iconType="crop" />{' '}
                <Text
                    theme="body1-alt"
                    className={styles['toolbar-button-label']}
                >
                    Crop & Position
                </Text>
            </Button>
            <Button
                data-id="delete"
                onClick={onClickDelete}
                className={styles['toolbar-button']}
            >
                <SvgIcon iconType="trash" />{' '}
                <Text
                    theme="body1-alt"
                    className={styles['toolbar-button-label']}
                >
                    Delete
                </Text>
            </Button>
        </nav>
    );
};

Toolbar.propTypes = {
    hasLowResPhoto: PropTypes.bool,
    onClickCrop: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
};

Toolbar.defaultProps = {
    hasLowResPhoto: false,
};

export default Toolbar;
