import PropTypes from 'prop-types';
import React from 'react';
import CloseButton from 'stillnovel/components/UI/CloseButton';
import Text from 'stillnovel/components/UI/Text';

import styles from './LocationCard.scss';

const LocationCard = ({ input, onClear }) => {
    return (
        <div className={styles.root}>
            <Text theme="form-text-field">
                <span className={styles.label}>Selected location:</span>{' '}
                {input?.value?.city?.long_name}
                {input?.value?.state?.short_name
                    ? `, ${input?.value?.state?.short_name}`
                    : ''}
            </Text>
            <CloseButton onClick={onClear} className={styles.closeButton} />
            {/* {locationData?.location?.lat}, {locationData?.location?.lng} */}
        </div>
    );
};

LocationCard.propTypes = {
    input: PropTypes.shape({
        value: PropTypes.shape({
            city: PropTypes.shape({
                long_name: PropTypes.any,
            }),
            state: PropTypes.shape({
                short_name: PropTypes.any,
            }),
        }),
    }),
    onClear: PropTypes.any,
};

export default LocationCard;
